@tailwind base;
@tailwind components;
@tailwind utilities;
@import './global.css';
@import './notificationStyles.css';
/* src/index.css */

@font-face {
  font-family: 'Sen';
  src: url('../assets/fonts/Sen-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sen';
  src: url('../assets/fonts/Sen-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Sen';
  src: url('../assets/fonts/Sen-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sen';
  src: url('../assets/fonts/Sen-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Sen';
  src: url('../assets/fonts/Sen-ExtraBold.ttf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

.checkbox-custom {
  @apply h-5 w-5 appearance-none border-stone-400 dark:border-zinc-300 rounded-md border checked:bg-[url('../assets/images/commonIcons/check.svg')] checked:bg-[length:17px] checked:bg-center checked:bg-no-repeat checked:bg-orange-500 hover:ring-1 hover:ring-zinc-500 dark:hover:ring-gray-300 focus:outline-none;
}

.floating-input-custom {
  @apply p-4 block w-full bg-slate-100 text-stone-900 dark:text-zinc-300 border border-slate-200 rounded-lg text-base placeholder:text-transparent focus:border-zinc-300 focus:ring-zinc-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-stone-700 dark:border-stone-700 dark:focus:ring-neutral-600 shadow-md dark:shadow-black focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2 placeholder-select-none;
}

.floating-label-custom {
  @apply absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] text-zinc-500 dark:text-stone-400;
  @apply peer-disabled:opacity-50 peer-disabled:pointer-events-none select-none;
  @apply peer-focus:scale-90 peer-focus:translate-x-[0.1rem] peer-focus:-translate-y-3 peer-focus:text-gray-500 dark:peer-focus:text-neutral-400;
  @apply peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-[0.1rem] peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:text-gray-400 dark:peer-[:not(:placeholder-shown)]:text-stone-400;
}

.placeholder-select-none::placeholder {
  @apply select-none;
}