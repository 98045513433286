* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Sen', sans-serif;
}

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(43%) sepia(97%) saturate(1046%) hue-rotate(345deg) brightness(101%) contrast(102%);
}

.time-input::-webkit-calendar-picker-indicator {
  filter: invert(0.5) sepia(1) saturate(8) hue-rotate(10deg);
}
.time-input::-webkit-time-picker-indicator {
  filter: invert(0.5) sepia(1) saturate(8) hue-rotate(10deg);
}
.time-input::-moz-calendar-picker-indicator {
  filter: invert(0.5) sepia(1) saturate(8) hue-rotate(10deg);
}
.time-input::-ms-clear {
  filter: invert(0.5) sepia(1) saturate(8) hue-rotate(10deg);
}
