/* notificationStyles.css */

/* Main container styles */
.Toastify__toast {
  @apply md:rounded-xl min-h-16 md:border-2 shadow-lg relative p-3 opacity-95;
}

.Toastify__toast-body {
  @apply m-0 text-sm flex items-start justify-center;
}

/* Success notification */
.Toastify__toast--success {
  @apply bg-emerald-50 text-stone-900 relative border-emerald-500;
}

/* Error notification */
.Toastify__toast--error {
  @apply bg-red-50 text-stone-900 relative border-red-300;
}

/* Warning notification */
.Toastify__toast--warning {
  @apply bg-yellow-50 text-stone-900 relative border-yellow-300;
}

/* Info notification */
.Toastify__toast--info {
  @apply bg-blue-50 text-stone-900 relative border-blue-300;
}

/* Icon styles */
.Toastify__toast-icon {
  @apply w-6 h-6 mr-4;
}

/* Close button */
.Toastify__close-button {
  @apply text-gray-400 opacity-100 p-2 hover:bg-black/5 rounded-full transition-colors;
}

.Toastify__close-button:hover {
  @apply opacity-80;
}

/* Dark theme styles */
.dark .Toastify__toast--success {
  @apply bg-gradient-to-r from-emerald-700 to-green-700/80 text-zinc-50 border-emerald-300;
}

.dark .Toastify__toast--error {
  @apply bg-gradient-to-r from-red-700/80 to-red-500 text-zinc-50;
}

.dark .Toastify__toast--warning {
  @apply bg-gradient-to-r from-orange-500/80 to-amber-400 text-zinc-50 border-amber-300;
}

.dark .Toastify__toast--info {
  @apply bg-gradient-to-r from-blue-700/70 to-sky-600 text-zinc-50;
}

.dark .Toastify__close-button {
  @apply text-zinc-200 hover:bg-white/5;
}